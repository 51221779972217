@import "../scss/variables";

$primary-dark: #4f8bf9;

/*------------------------------------------------------------------
[Dark-stylesheet Stylesheet]

Project        :   Azira - HTML5 Bootstrap Admin Template
Create Date    :   05/02/20
Copyright      :   Spruko Technologies Private Limited 
Author         :   SprukoSoft
Author URL     :   https://themeforest.net/user/spruko
Support	       :   support@spruko.com
License        :   Licensed under ThemeForest License

-------------------------------------------------------------------*/


body.dark-theme {
    color: $white;
    background-color: #3d425d !important;
  }
  
  .dark-theme {
    .card {
      background: $dark-theme  !important;
      border: 0 !important;
      box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
    }
  
    .side-header {
      border-right: 0;
    }
  
    .main-header-message > a, .main-header-notification > a, .nav-item.full-screen > a, .card-title {
      color: $white;
    }
  
    .card-dashboard-audience-metrics {
      .card-header, .card-body {
        background-color: $dark-theme;
      }
    }
  
    .card-header {
      background-color: $dark-theme;
      border-bottom: 0;
    }
  
    .border-bottom {
      border-bottom: 1px solid rgba(234, 236, 241, 0.1) !important;
    }
  
    .border-top {
      border-top: 1px solid rgba(234, 236, 241, 0.1) !important;
    }
  
    .border-right {
      border-right: 1px solid rgba(234, 236, 241, 0.1) !important;
    }
  
    .border-left {
      border-left: 1px solid rgba(234, 236, 241, 0.1) !important;
    }
  
    .border {
      border: 1px solid rgba(234, 236, 241, 0.1) !important;
    }
  
    .table tbody tr, .table-bordered thead th {
      background-color: $dark-theme;
    }
  }
  
  .table-bordered thead td {
    background-color: $dark-theme;
  }
  
  .dark-theme {
    .table {
      color: $white-7;
    }
  
    .card-dashboard-audience-metrics .card-body h4 {
      color: #fcfcfd;
    }
  
    .progress {
      background-color: rgb(51, 57, 99);
    }
  
    .card-dashboard-audience-metrics .flot-chart .flot-x-axis > div span:last-child {
      color: #b9c6de;
    }
  
    .main-footer {
      background-color: $dark-theme;
      border-top: 1px solid rgb(53, 59, 97);
    }
  
    .sidebar {
      &.sidebar-right {
        box-shadow: 5px 7px 26px -5px #030f2d !important;
      }
  
      .tabs-menu ul {
        border-bottom: 1px solid rgba(235, 234, 241, 0.1);
      }
    }
  
    .chat .contacts li {
      border-bottom: 1px solid rgba(227, 227, 247, 0.1);
    }
  
    .form-control {
      color: $white;
      background-color: rgb(51, 57, 99);
      border: 1px solid rgba(226, 232, 245, 0.2);
    }
  
    .list-group-item {
      background-color: $dark-theme;
      border: 1px solid rgba(231, 235, 243, 0.1);
    }
  
    .main-header-center .form-control {
      border-color: transparent !important;
      background-color: transparent !important;
    }
  
    .main-header {
      form[role="search"] {
        &.active input {
          background: $dark-theme;
		  color: $white;
        }
  
        button[type="reset"] {
          background: transparent;
        }
      }
  
      .input-group-btn .btn:hover i {
        color: $white;
      }
    }
  
    .main-header-notification .dropdown-menu {
      background-color: $dark-theme;
    }
  
    .notification-label {
      color: $white;
    }
  
    .main-notification-list a:hover, .main-message-list a:hover {
      background: $white-05;
    }
  
    .nav .nav-item .dropdown-menu {
      -webkit-box-shadow: 0px 0px 15px 1px rgb(4, 17, 56);
      box-shadow: 0px 0px 15px 1px rgb(4, 17, 56);
    }
  
    .notification-subtext {
      color: $white-3;
    }
  
    .main-header-message .dropdown-footer, .main-header-notification .dropdown-footer {
      border-top: 1px solid rgba(220, 225, 239, 0.1);
      background: #2b3054;
    }
  
    .dropdown-menu {
      color: $white;
      background-color: $dark-theme;
      border: 1px solid rgba(138, 153, 191, 0.125);
    }
  
    .main-message-list a {
      .name {
        color: $white;
      }
  
      .time, .desc {
        color: rgb(200, 203, 210);
      }
    }
  
    .task-line a {
      color: $white;
    }
  
    .latest-tasks .nav-tabs .nav-link {
      color: $secondary;
      background: transparent;
    }
  
    .chips p {
      color: $white-5;
    }
  
    .chip {
      color: $white;
      background-color: rgba(245, 246, 251, 0.1);
    }
  }
  
  @media (min-width: 1245px) {
    .dark-theme .main-header-center .form-control {
      border-color: transparent !important;
      background-color: transparent !important;
    }
  }
  
  .chip:hover {
    background: #0039e6;
    color: $white;
  }
  
  .dark-theme {
    .latest-tasks .check-box .ckbox span:before {
      border: 1px solid rgba(217, 226, 255, 0.1);
    }
  
    #global-loader {
      background: #1a233a;
    }
  
    .app-sidebar {
      background: $dark-theme;
      border-top: 1px solid #efedf5;
      box-shadow: 0 0 10px rgba(20, 28, 43, 0.8);
    }
  
    .main-sidebar-header {
      background: $dark-theme;
      border-bottom: 1px solid rgba(222, 228, 236, 0.1);
      border-right: 1px solid rgba(222, 228, 236, 0.03);
    }
  
    .main-sidebar-loggedin .media-body h6 {
      color: $white;
    }
  
    .side-menu__item {
      color: $white-7;
    }
  
    .slide.is-expanded:before {
      background: rgba(227, 231, 237, 0.1);
    }
  
    .slide-menu .slide-item:before {
      border-color: #6d7582;
      color: $white;
    }
  
    .main-logo, .logo-1, .desktop-logo.active.logo-light {
      display: none;
    }
  
    .main-logo.dark-theme {
      display: block;
    }
  
    .desktop-logo {
      margin: 0 auto;
    }
  
    .open-toggle svg g, .close-toggle svg g {
      fill: $white;
    }
  
    .angle {
      color: rgb(200, 203, 210);
    }
  
    .main-header-center {
      .btn:hover, .sp-container button:hover {
        color: $white;
      }
    }
  
    .sp-container .main-header-center button:hover {
      color: $white;
    }
  
    .main-header-center {
      .btn:focus, .sp-container button:focus {
        color: $white;
      }
    }
  
    .sp-container .main-header-center button:focus, .main-header .input-group-btn .btn i {
      color: $white;
    }
  
    .main-mail-item {
      &.unread {
        background-color: $dark-theme;
      }
  
      border-top: 1px solid rgba(227, 231, 237, 0.1);
      border-bottom: 1px solid rgba(227, 231, 237, 0.1);
    }
  
    .main-content-title {
      color: $white;
    }
  
    .main-mail-options {
      border: 1px solid rgb(53, 59, 97);
      border-bottom: 0;
    }
  
    .main-mail-list {
      border: 1px solid rgba(227, 231, 237, 0.1);
    }
  
    .main-mail-item {
      background-color: $dark-theme;
    }
  
    .main-mail-subject strong {
      color: $white-7;
    }
  
    .ckbox span:before {
      background-color: rgba(227, 231, 237, 0.1);
      border: 1px solid rgba(227, 231, 237, 0.1);
    }
  
    .main-mail-star {
      color: rgb(51, 57, 99);
    }
  
    .main-nav-column .nav-link {
      color: $secondary;
  
      &:hover, &:focus {
        color: $white;
      }
    }
  
    .btn-light {
      color: $white;
      background-color: rgb(53, 59, 97);
      border-color: rgba(189, 198, 214, 0.2);
    }
  
    .main-nav-column .nav-link {
      &:hover i:not([class*=' tx-']), &:focus i:not([class*=' tx-']) {
        color: $white;
      }
  
      + .nav-link {
        border-top: 1px solid rgba(180, 189, 206, 0.3);
      }
    }
  
    .nav-search .input-group-text {
      color: $white;
      background-color: $white-2;
      border: 1px solid rgb(51, 57, 99);
      border-left: 0;
    }
  
    .main-nav-column .nav-link.active {
      color: $primary-dark !important;
  
      &:hover, &:focus {
        color: $primary-dark !important;
      }
    }
  
    .main-mail-header .btn-group .btn {
      border-color: #555c6e;
      background-color: #555c6e;
    }
  }
  
  /*----- Left-Sidemenu -----*/
  
  @media (max-width: 991px) and (min-width: 574px) {
    .dark-theme .responsive-logo {
      .dark-logo-2, .logo-2 {
        dispLay: none;
      }
  
      .dark-logo-1 {
        height: 2rem;
      }
    }
  }
  
  @media (max-width: 574px) {
    .dark-theme .responsive-logo {
      .logo-2 {
        dispLay: none;
      }
  
      .dark-logo-2 {
        dispLay: block;
        height: 2.5rem;
      }
  
      .dark-logo-1 {
        dispLay: none;
      }
    }
  }
  
  /*----- Left-Sidemenu -----*/
  
  .main-mail-header .btn-group .sp-container button, .sp-container .main-mail-header .btn-group button {
    border-color: #555c6e;
    background-color: #555c6e;
  }
  
  .dark-theme {
    .main-mail-header .btn-group {
      .btn.disabled, .sp-container button.disabled {
        background-color: #555c6e;
        color: #e2e8f5;
        border-color: #555c6e;
      }
    }
  
    .sp-container .main-mail-header .btn-group button.disabled {
      background-color: #555c6e;
      color: #e2e8f5;
      border-color: #555c6e;
    }
  
    .main-mail-header .btn-group {
      .btn:hover, .sp-container button:hover {
        color: $white;
        background-color: $white-3;
        border-left: 0;
      }
    }
  
    .sp-container .main-mail-header .btn-group button:hover {
      color: $white;
      background-color: $white-3;
      border-left: 0;
    }
  
    .main-mail-header .btn-group {
      .btn:focus, .sp-container button:focus {
        color: $white;
        background-color: $white-3;
        border-left: 0;
      }
    }
  
    .sp-container .main-mail-header .btn-group button:focus {
      color: $white;
      background-color: $white-3;
      border-left: 0;
    }
  
    .card-header, .card-footer {
      position: relative;
      border-color: rgb(53, 59, 97);
    }
  
    hr {
      border-color: rgb(53, 59, 97);
    }
  
    .main-content-label, .card-table-two .card-title, .card-dashboard-eight .card-title {
      color: $white;
    }
  
    .form-label {
      color: #cfdaec;
    }
  
    .select2-container--default .select2-selection--single {
      background-color: rgb(51, 57, 99);
      border-color: rgb(53, 59, 97);
  
      .select2-selection__rendered {
        color: #cfdaec;
      }
    }
  
    .select2-dropdown {
      background-color: $dark-theme;
      border-color: $white-05;
    }
  
    .select2-container--default {
      .select2-results__option[aria-selected="true"] {
        background-color: $white-05;
      }
  
      .select2-search--dropdown .select2-search__field {
        border-color: rgba(226, 232, 245, 0.2);
        background: #2c3252;
        color: $white;
      }
    }
  
    .main-nav-line-chat {
      border-bottom: 1px solid rgb(53, 59, 97) !important;
    }
  
    .main-nav-line .nav-link {
      color: rgb(200, 203, 210);
    }
  
    .main-chat-msg-name h6 {
      color: $white;
    }
  
    .main-chat-header {
      border-bottom: 1px solid rgb(53, 59, 97);
      box-shadow: 2px 3px 14px #1a233a;
    }
  
    .main-nav-line-chat .nav-link.active {
      color: $primary-dark !important;
      background: transparent;
    }
  
    .main-chat-contacts-more {
      background-color: $primary-dark;
    }
  
    .main-chat-list {
      .media {
        &.new {
          background-color: $dark-theme;
  
          .media-body p {
            color: #a9b2c7;
          }
  
          .media-contact-name span:first-child {
            color: #f3f6fb;
          }
        }
  
        border-bottom: 1px solid rgb(53, 59, 97);
      }
  
      .media-contact-name span:first-child {
        color: #fff !important;
      }
  
      .media.selected {
        background-color: rgb(53, 59, 97);
      }
    }
  
    .main-chat-contacts-wrapper {
      border-bottom: 1px solid rgb(53, 59, 97);
    }
  
    .main-chat-list .media {
      &:hover, &:focus {
        background-color:rgb(53, 59, 97);
      }
  
      &.selected .media-body p {
        color: #b7bfd2;
      }
    }
  
    .main-msg-wrapper {
      background-color: rgb(53, 59, 97);
      color: $white;
    }
  
    .main-chat-footer {
      border-top: 1px solid rgb(53, 59, 97);
      background-color: $dark-theme;
      z-index: 999;
  
      .form-control {
        background: transparent;
        border-color: transparent;
      }
    }
  
    .irs-line-mid, .irs-line-left, .irs-line-right {
      background-color: rgb(53, 59, 97);
    }
  
    .irs-min, .irs-max {
      color: $white;
      background: rgb(51, 57, 99);
    }
  
    .main-calendar .fc-header-toolbar h2 {
      color: $white;
    }
  
    .ui-datepicker {
      background-color: $dark-theme;
      box-shadow: 0 0 24px rgba(20, 28, 43, 0.6);
      border: 1px solid rgb(51, 57, 99);
  
      .ui-datepicker-calendar td {
        border: 1px solid rgb(51, 57, 99);
        background-color: $dark-theme;
  
        span, a {
          color: rgb(200, 203, 210);
        }
      }
  
      .ui-datepicker-title, .ui-datepicker-calendar th {
        color: $white;
      }
    }
  
    .main-datepicker .ui-datepicker .ui-datepicker-calendar th {
      color: #fcfcfc;
    }
  
    .card--calendar .ui-datepicker .ui-datepicker-header {
      border-bottom: 1px solid rgba(227, 227, 227, 0.1);
    }
  
    .ui-datepicker .ui-datepicker-calendar td a:hover {
      background-color: $primary-dark;
      color: $white;
    }
  
    .main-calendar {
      .fc-view > table {
        background-color: $dark-theme;
      }
  
      .fc-head-container .fc-day-header {
        color: $white;
      }
  
      .fc-view {
        .fc-day-number {
          color: $white;
        }
  
        .fc-other-month {
          background-color: rgba(40, 92, 247, 0.07);
        }
      }
  
      .fc-content {
        border-color: rgb(53, 59, 97);
      }
    }
  }
  
  .main-calendar .fc-divider {
    border-color: rgb(53, 59, 97);
  }
  
  .dark-theme .main-calendar {
    .fc-list-heading td, .fc-list-view, .fc-popover, .fc-row, tbody, td {
      border-color: rgb(53, 59, 97);
    }
  }
  
  .main-calendar th {
    border-color: rgb(53, 59, 97);
  }
  
  .dark-theme {
    .main-calendar {
      thead {
        border-color: rgb(53, 59, 97);
      }
  
      .fc-view .fc-day-number {
        &:hover, &:focus {
          color: $white;
          background-color: transparent;
        }
      }
  
      td.fc-today {
        background-color: $primary-dark;
      }
  
      .fc-view > table > {
        thead {
          th, td {
            border-color: rgb(53, 59, 97);
          }
        }
  
        tbody > tr > td {
          border-color: rgb(53, 59, 97);
        }
      }
  
      .fc-header-toolbar button {
        background-color: #384361;
        border: 1px solid rgb(53, 59, 97);
  
        &.fc-state-active {
          background-color: $primary-dark;
        }
      }
  
      .fc-view {
        &.fc-listMonth-view .fc-list-item, &.fc-listWeek-view .fc-list-item {
          background-color: #333d5a;
        }
      }
    }
  
    .fc-unthemed {
      .fc-divider, .fc-list-heading td, .fc-popover .fc-header {
        background: #384361;
      }
    }
  
    .main-calendar .fc-view {
      &.fc-listMonth-view .fc-list-item-title .fc-desc, &.fc-listWeek-view .fc-list-item-title .fc-desc {
        color: $white-6;
      }
  
      &.fc-listMonth-view .fc-list-item-title a, &.fc-listWeek-view .fc-list-item-title a, &.fc-listMonth-view .fc-list-heading-main span:last-child, &.fc-listWeek-view .fc-list-heading-main span:last-child {
        color: $white;
      }
    }
  
    .main-contact-info-header .media-body p {
      color: $white-6;
    }
  
    .tx-inverse {
      color: rgb(255, 255, 255);
    }
  
    .contact-icon:hover {
      background: rgb(51, 57, 99);
      color: $white;
    }
  
    .main-contact-info-header {
      border-bottom: 1px solid rgb(53, 59, 97);
    }
  
    .main-contact-info-body {
      .media-body {
        span {
          color: $white-8;
        }
  
        label {
          color: rgb(200, 203, 210);
        }
      }
  
      .media + .media::before {
        border-top: 1px dotted rgba(226, 232, 245, 0.2);
      }
    }
  
    .main-contact-body {
      span {
        color: rgb(200, 203, 210);
      }
  
      h6 {
        color: $white-8;
      }
    }
  
    .main-contact-item {
      &.selected {
        border-left-color: $primary-dark;
        border-top-color: rgba(226, 232, 245, 0.01) !important;
        border-bottom-color: rgba(226, 232, 245, 0.01) !important;
        background-color: rgb(51, 57, 99);
      }
  
      + .main-contact-item {
        border-top-color: rgba(227, 231, 237, 0.19);
  
        &::before {
          border-top: 1px solid rgba(227, 231, 237, 0.05);
        }
      }
  
      &:hover, &:focus {
        background-color: rgb(51, 57, 99);
        border-top-color: rgba(227, 231, 237, 0.1);
        border-bottom-color: rgba(227, 231, 237, 0.1);
      }
    }
  
    .main-contact-label::after {
      border-bottom: 1px solid rgba(227, 231, 237, 0.1);
    }
  
    #ui_notifIt.default {
      background-color: #3e465b;
      border: 1px solid rgba(227, 231, 237, 0.19);
    }
  
    .notifit_confirm, .notifit_prompt {
      background-color: #3e465b;
    }
  
    .alert .close {
      color: $white;
      opacity: .7;
    }
  
    .tree {
      li {
        a {
          text-decoration: none;
          color: $white-8;
        }
  
        color: rgb(200, 203, 210);
      }
  
      ul {
        li:last-child:before {
          background: $dark-theme;
        }
  
        &:before {
          border-left: 1px solid rgba(227, 227, 227, 0.2);
        }
  
        li:before {
          border-top: 1px solid rgba(227, 227, 227, 0.2);
        }
      }
    }
  
    .text-muted {
      color: #97a3b9 !important;
    }
  
    .main-icon-group {
      color: $white-8;
    }
  
    .main-icon-list {
      border: 1px solid rgba(225, 230, 241, 0.1);
    }
  
    .table thead th {
      border-bottom: 1px solid rgba(227, 231, 237, 0.1);
      border-top: 0;
    }
  
    .table-hover tbody tr:hover {
      color: $white;
      background-color: #30365f !important;
    }
  
    table.dataTable {
      tbody td.sorting_1 {
        background-color: $dark-theme;
      }
  
      border: 1px solid rgb(53, 59, 97);
  
      thead {
        th, td {
          color: $white;
        }
  
        .sorting_asc, .sorting_desc {
          background-color: #313765;
        }
      }
    }
  
    #example-delete.table thead th {
      border-bottom: 0;
    }
  
    .dataTables_wrapper {
      .dataTables_length, .dataTables_filter, .dataTables_info, .dataTables_processing, .dataTables_paginate {
        color: $white;
      }
  
      .dataTables_filter input {
        border: 1px solid rgba(226, 232, 245, 0.2);
      }
  
      .dataTables_paginate .paginate_button {
        background-color: rgba(227, 231, 237, 0.1);
      }
    }
  
    .page-link {
      color: $white;
      background-color:#1d2244;
    }
  
    .dataTables_wrapper .dataTables_paginate {
      .paginate_button.active {
        background: $primary-dark !important;
      }
  
      .page-item.disabled .page-link {
        background-color: transparent;
      }
    }
  
    select option {
      background: $dark-theme;
    }
  
    table.dataTable tbody tr.selected {
      background: rgba(242, 244, 247, 0.1);
    }
  
    .example {
      padding: 1rem;
      border: 1px solid rgba(225, 230, 241, 0.1);
      border-bottom: 0;
    }
  
    #basic-alert .alert .close, #dismiss-alerts .alert .close {
      color: $black;
      opacity: .3;
    }
  
    #icon-dismissalerts {
      .alert .close {
        color: $black;
        opacity: .3;
      }
  
      .alert-default.alert-dismissible .close {
        color: $white;
      }
    }
  
    .main-table-reference > {
      thead > tr > {
        th, td {
          border: 1px solid rgb(53, 59, 97);
        }
      }
  
      tbody > tr > {
        th, td {
          border: 1px solid rgb(53, 59, 97);
        }
      }
    }
  
    pre {
      color: #dfe1ef;
      background-color: #1d2244;
      text-shadow: 0 1px #242266;
    }
  
    code.language-markup {
      background: transparent;
      border: transparent;
    }
  
    .token {
      color: #dc2a2a;
  
      &.selector, &.attr-name, &.string, &.char, &.builtin, &.inserted {
        color: #4e9cf1;
      }
  
      &.atrule, &.attr-value, &.keyword {
        color: #e40dae;
      }
  
      &.operator, &.entity, &.url {
        color: #ecc494;
        background: rgb(51, 57, 99);
      }
    }
  
    .language-css .token.string, &.style .token.string {
      color: #ecc494;
      background: rgb(51, 57, 99);
    }
  
    .highlight {
      border: 1px solid rgba(225, 230, 241, 0.2) !important;
      background: #1b2542;
    }
  
    .clipboard-icon {
      background: #1d2244;
      border: 1px solid rgba(225, 230, 241, 0.1);
    }
  
    .main-table-reference {
      > thead > tr > {
        th, td {
          background: rgb(51, 57, 99) !important;
          border: 1px solid rgba(225, 230, 241, 0.1) !important;
        }
      }
  
      background: transparent;
    }
  
    .breadcrumb-style1 .breadcrumb-item a, .breadcrumb-style2 .breadcrumb-item a, .breadcrumb-style3 .breadcrumb-item a {
      color: rgb(200, 203, 210);
    }
  
    .dropdown-item {
      color: $white-6;
  
      &:hover, &:focus {
        background: rgb(51, 57, 99);
      }
    }
  
    .dropdown-divider {
      border-top: 1px solid rgba(227, 231, 237, 0.16);
    }
  
    .img-thumbnail {
      background-color: rgb(51, 57, 99);
      border: 1px solid rgb(53, 59, 97);
  
      p {
        color: $white-5;
        font-size: 13px;
      }
    }
  
    .bd {
      border-color: rgb(53, 59, 97);
    }
  
    .bg-light {
      background-color:#353b61 !important;
    }
  
    .main-nav .nav-link {
      &:hover, &:focus {
        color: $white;
      }
    }
  
    .nav-pills .nav-link {
      color: rgb(200, 203, 210);
  
      &:hover, &:focus {
        color: $white;
      }
  
      &.active {
        color: #fff !important;
      }
    }
  
    .main-nav .nav-link {
      color: rgb(200, 203, 210);
    }
  
    .bg-gray-300 {
      background-color: #323b54;
    }
  
    .nav-tabs .nav-link {
      &.active {
        background-color: rgb(72, 85, 234);
        color: $white;
      }
  
      color: rgb(255, 255, 255);
    }
  
    .main-nav-line .nav-link.active::before {
      background-color:$primary-dark;
    }
  
    #tab .bg-gray-300 {
      background-color: #37415f;
      border-bottom: 1px solid #404563;
    }
  
    .nav-tabs .nav-link {
      &:hover, &:focus {
        background-color: $white-5;
        color: $white;
      }
    }
  
    .popover-static-demo {
      background-color: #1d2244;
    }
  
    .popover {
      background-color: #2b3054;
      border: 1px solid rgb(72, 80, 101);
    }
  
    .popover-body {
      color: rgb(200, 203, 210);
    }
  
    .popover-header {
      color: $white-7;
      background-color: #2b3054;
      border-color: rgba(133, 140, 152, 0.2);
    }
  
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      border-top-color: rgb(78, 86, 109);
    }
  
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      border-top-color: #2b3054;
    }
  
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      border-bottom-color: #373e52;
    }
  
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      border-bottom-color: rgb(78, 86, 109);
    }
  
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after, .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      border-left-color: #373e52;
    }
  
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      border-right-color: #373e52;
    }
  
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      border-right-color: rgb(78, 86, 109);
    }
  
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      border-left-color: rgb(78, 86, 109);
    }
  
    .bg-gray-200 {
      background-color: #1d2244;
    }
  
    .media-body {
      font-size: 13px;
      color: $white-3;
    }
  
    .bg-gray-100 {
      background-color: #373c5d;
    }
  
    .tooltip-static-demo {
      background-color: #1d2244;
    }
  
    .toast-header {
      border-bottom-color: rgba(205, 212, 224, 0.2);
    }
  
    .toast {
      background-color: rgb(51, 57, 99);
      border-color: rgba(84, 90, 109, 0.7);
    }
  
    .toast-header {
      color: rgba(255, 255, 255, 0.58);
    }
  
    .bootstrap-tagsinput {
      .badge {
        margin-top: 0;
      }
  
      background-color: #1d2244;
    }
  
    .tag {
      color: $white;
      background-color: rgba(239, 239, 245, 0.1);
    }
  
    .accordion {
      .card-header a {
        color: $white;
        background-color: rgb(51, 57, 99);
      }
  
      .card-body {
        background-color: rgb(51, 57, 99);
      }
  
      .card-header a.collapsed {
        &:hover, &:focus {
          color: $white;
          background-color: $primary-dark;
        }
      }
    }
  
    .modal-content {
      background-color: $dark-theme;
      border: 1px solid rgba(255, 255, 255, 0.18);
    }
  
    .modal-header {
      border-bottom: 1px solid rgb(53, 59, 97);
    }
  
    .modal-title {
      color: $white;
    }
  
    .modal-footer {
      border-top: 1px solid rgb(53, 59, 97);
    }
  
    .close {
      color: #f7f6f6;
      text-shadow: 0 1px 0 #18213c;
    }
  
    .modal-content-demo .modal-body h6 {
      color: $white;
    }
  
    .vtimeline {
      .timeline-wrapper {
        .timeline-panel {
          background: #1d2244;
          box-shadow: 0 8px 16px 0 rgba(47, 53, 84, 0.24);
  
          &:after {
            border-left: 14px solid #1d2244;
            border-right: 0 solid #1d2244;
          }
        }
  
        &.timeline-inverted .timeline-panel:after {
          border-right: 14px solid #1d2244;
          border-left: 0 solid #1d2244;
        }
      }
  
      &:before {
        background-color: #1d2244;
      }
    }
  
    .timeline-body {
      color: $white-6;
    }
  
    .sweet-alert {
      background-color: $dark-theme;
  
      h2 {
        color: $white;
      }
    }
  
    .btn-outline-light {
      border-color: rgba(151, 163, 185, 0.2);
      color: $color;
  
      &:hover, &:focus {
        background-color: #3d4765 !important;
        border: 1px solid #455177 !important;
        box-shadow: none !important;
        color: #fff !important;
      }
    }
  
    .dropdown .fe-more-vertical {
      color: $white-6;
    }
  
    .main-content-body-profile .nav {
      border-bottom: 1px solid rgb(53, 59, 97);
    }
  
    .card-body + .card-body {
      border-top: 1px solid rgb(53, 59, 97);
    }
  
    .rating-stars {
      input {
        color: $white;
        background-color: #1d2244;
        border: 1px solid rgba(234, 237, 241, 0.1);
      }
  
      .rating-stars-container .rating-star {
        color: #1d2244;
  
        &.is--active, &.is--hover {
          color: #f1c40f !important;
        }
      }
    }
  
    .br-theme-bars-horizontal .br-widget a {
      &.br-active, &.br-selected {
        background-color: $primary-dark !important;
      }
    }
  
    .br-theme-bars-pill .br-widget a {
      &.br-active, &.br-selected {
        background-color: $primary-dark !important;
        color: white;
      }
    }
  
    .br-theme-bars-1to10 .br-widget a, .br-theme-bars-movie .br-widget a, .br-theme-bars-horizontal .br-widget a {
      background-color: #1d2244;
    }
  
    .br-theme-bars-1to10 .br-widget a {
      &.br-active, &.br-selected {
        background-color: $primary-dark !important;
      }
    }
  
    .br-theme-bars-movie .br-widget a {
      &.br-active, &.br-selected {
        background-color: $primary-dark !important;
      }
    }
  
    .br-theme-bars-square .br-widget a {
      border: 2px solid #4f576f;
      background-color: #1d2244;
      color: #8694a5;
  
      &.br-active, &.br-selected {
        border: 2px solid $primary-dark;
        color: $primary-dark;
      }
    }
  
    .br-theme-bars-pill .br-widget a {
      background-color: #1d2244;
    }
  
    .custom-file-label {
      color: $white-7;
      background-color: rgba(255, 255, 255, 0.07);
      border: 1px solid rgba(255, 255, 255, 0.07);
  
      &::after {
        color: $white;
        background-color: $primary-dark;
        border: 1px solid $primary-dark;
      }
    }
  
    .input-group-text {
      color: $white-5;
      background-color: rgb(51, 57, 99);
      border: 1px solid rgba(255, 255, 255, 0.12);
    }
  
    .sp-replacer {
      border-color: rgba(255, 255, 255, 0.12);
      background-color: #2d3653;
  
      &:hover, &:focus {
        border-color: $white-2;
      }
    }
  
    .sp-container {
      background-color: $dark-theme;
      border-color: rgba(226, 232, 245, 0.2);
    }
  
    .select2-container--default {
      &.select2-container--focus .select2-selection--multiple {
        border-color: rgb(51, 57, 99);
        background: rgb(51, 57, 99);
      }
  
      .select2-selection--multiple {
        background-color: rgb(51, 57, 99) !important;
        border-color: rgb(51, 57, 99);
        background-color: rgb(51, 57, 99) !important;
        color: $white;
        border-color: rgb(51, 57, 99);
        background-color: rgb(51, 57, 99) !important;
        border-color: rgb(51, 57, 99);
      }
  
      &.select2-container--disabled .select2-selection--multiple {
        background-color: rgb(51, 57, 99) !important;
      }
    }
  
    .SumoSelect {
      > {
        .CaptionCont {
          border: 1px solid rgba(225, 230, 241, 0.1);
          color: #99a6b7;
          background-color: rgb(65, 69, 101);
        }
  
        .optWrapper {
          background: $dark-theme;
          border: 1px solid rgba(234, 234, 236, 0.15);
          box-shadow: 0 2px 17px 2px rgb(7, 4, 86);
        }
      }
  
      .select-all {
        border-bottom: 1px solid rgba(234, 234, 236, 0.15);
        background-color: $dark-theme;
      }
  
      > .optWrapper > {
        .options li.opt {
          border-bottom: 1px solid rgba(234, 234, 236, 0.15);
        }
  
        .MultiControls {
          border-top: 1px solid rgba(234, 234, 236, 0.15);
          background-color: $dark-theme;
        }
      }
  
      &.open > .optWrapper {
        box-shadow: 0 2px 17px 2px rgb(28, 33, 64);
      }
  
      > .optWrapper {
        > .options li.opt:hover {
          background-color: rgba(244, 245, 245, 0.1);
        }
  
        &.multiple > .options li.opt span i {
          border: 1px solid rgb(51, 57, 99);
          background-color: rgb(51, 57, 99);
        }
      }
  
      .select-all > span i {
        border: 1px solid rgb(51, 57, 99);
        background-color: rgb(51, 57, 99);
      }
    }
  
    .dropify-wrapper {
      background-color: rgb(51, 57, 99);
      border: 1px solid rgba(239, 242, 247, 0.07);
      color: $white;
  
      .dropify-preview {
        background-color: rgb(51, 57, 99);
      }
  
      &:hover {
        background-image: -webkit-linear-gradient(135deg, rgba(250, 251, 254, 0.05) 25%, transparent 25%, transparent 50%, rgba(250, 251, 254, 0.1) 50%, rgba(250, 251, 254, 0.1) 75%, transparent 75%, transparent);
        background-image: linear-gradient(-45deg, rgba(250, 251, 254, 0.1) 25%, transparent 25%, transparent 50%, rgba(250, 251, 254, 0.1) 50%, rgba(250, 251, 254, 0.1) 75%, transparent 75%, transparent);
        -webkit-animation: stripes 2s linear infinite;
        animation: stripes 2s linear infinite;
      }
    }
  
    .ff_fileupload_wrap .ff_fileupload_dropzone {
      border: 2px dashed #5e6882;
      background-color: #414565;
      background-image: url(../plugins/fancyuploder/fancy_upload-dark.png);
  
      &:hover, &:focus, &:active {
        background-color: rgb(51, 57, 99);
        border-color: rgb(51, 57, 99);
      }
    }
  
    .main-form-group {
      border: 1px solid rgb(53, 59, 97);
  
      .form-control {
        padding: 0 15px;
      }
    }
  
    .parsley-style-1 {
      .parsley-input.parsley-error .form-control, .parsley-checkbox.parsley-error, .parsley-select.parsley-error .select2-container--default .select2-selection--single {
        background-color: #1d2244;
      }
    }
  
    .wizard {
      border: 1px solid rgba(227, 231, 237, 0.1);
      background-color: #1d2244;
    }
  
    #wizard3.wizard.vertical > .content {
      border-bottom: 0;
    }
  
    .wizard > .content {
      > .title {
        color: $white;
      }
  
      border-top: 1px solid rgba(227, 231, 237, 0.1);
      border-bottom: 1px solid rgba(227, 231, 237, 0.1);
    }
  
    .ql-scrolling-demo {
      border: 2px solid rgb(51, 57, 99);
  
      .ql-container .ql-editor {
        color: $white-8;
      }
    }
  
    .ql-snow {
      .ql-picker-label {
        border: 1px solid rgba(255, 255, 255, 0.12);
        background-color: #1d2244;
      }
  
      .ql-stroke {
        stroke: $white-8;
      }
  
      .ql-editor, &.ql-toolbar button {
        color: $white-8;
      }
  
      .ql-picker {
        color: $white-5;
      }
  
      &.ql-toolbar {
        border: 2px solid rgb(51, 57, 99);
      }
  
      &.ql-container {
        border-color: rgb(51, 57, 99);
      }
  
      .ql-picker-options {
        background-color: $dark-theme;
      }
    }
  
    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
      border-color: #545b6d;
    }
  
    #modalQuill .modal-header {
      border-bottom: 0;
    }
  
    .main-profile-work-list .media-body {
      h6 {
        color: $white;
      }
  
      p {
        color: rgb(200, 203, 210);
      }
    }
  
    .main-profile-contact-list .media-body {
      div {
        color: $white-6;
      }
  
      span {
        color: $white;
      }
    }
  
    .plan-icon {
      border: 1px solid rgba(245, 246, 251, 0.1);
      background: rgba(245, 246, 251, 0.1);
    }
  
    .bg-success-transparent {
      background-color: rgba(77, 236, 146, 0.17) !important;
    }
  
    .bg-primary-transparent {
      background-color: rgba(53, 122, 249, 0.15) !important;
    }
  
    .bg-warning-transparent {
      background-color: rgba(245, 222, 143, 0.1) !important;
    }
  
    .bg-pink-transparent {
      background-color: rgba(249, 123, 184, 0.1) !important;
    }
  
    .bg-teal-transparent {
      background-color: rgba(166, 243, 243, 0.12) !important;
    }
  
    .bg-purple-transparent {
      background-color: rgba(150, 106, 228, 0.15)  !important;
    }
  
    .bg-danger-transparent {
      background-color: rgba(243, 161, 161, 0.1) !important;
    }
  
    .main-profile-name, .main-profile-body .media-body h6 {
      color: #fbfcff;
    }
  
    .main-profile-social-list .media-body a {
      color: $white;
      opacity: 0.5;
    }
  
    .profile-footer a {
      background: #1d2244;
      color: $white;
    }
  
    .billed-from h6 {
      color: #f4f5f8;
    }
  
    .invoice-title {
      color: #dce6e6;
    }
  
    .main-invoice-list {
      .media-body h6 {
        color: $white;
      }
  
      .selected {
        background-color:rgb(55, 60, 101);
        border-top-color: 1px dotted rgb(53, 59, 97);
        border-bottom-color: rgb(53, 59, 97);
        border-left-color: $primary-dark;
      }
  
      .media {
        + .media::before {
          border-top: 1px dotted transparent;
        }
  
        border: 1px dotted rgb(53, 59, 97);
  
        &:hover, &:focus {
          background-color: rgba(244, 245, 248, 0.1);
        }
      }
  
      .media-body p {
        color: #9da5b5;
  
        span {
          color: #cbcfd8;
        }
      }
    }
  
    .table-invoice tbody > tr > th:first-child .invoice-notes p {
      color: $white-6;
    }
  }
  
  @media (max-width: 767px) {
    .dark-theme .vtimeline .timeline-wrapper .timeline-panel:after {
      border-right: 14px solid #1d2244 !important;
      border-left: 0 solid #1d2244 !important;
    }
	.dark-theme .main-header {
		background: linear-gradient(45deg, #c53a7f, $primary-dark) !important;
	}
  }
  
  @media (min-width: 576px) {
    .dark-theme .wizard.vertical > {
      .content, .actions {
        border-left: 1px solid rgb(53, 59, 97);
      }
    }
  }
  
  .table-invoice tbody > tr > td:first-child .invoice-notes p {
    color: $white-6;
  }
  
  .dark-theme {
    .table-invoice tbody > tr > {
      th:first-child, td:first-child {
        color: $white-6;
      }
    }
  
    .billed-from p, .billed-to p {
      color: $white-5;
    }
  
    .card-invoice .tx-gray-600 {
      color: #eef0f3;
    }
  
    .billed-to h6 {
      color: #d3d8e2;
    }
  
    .invoice-info-row {
      + .invoice-info-row {
        border-top: 1px dotted rgba(226, 232, 245, 0.15);
      }
  
      span:first-child {
        color: $white-3;
      }
    }
  
    .main-invoice-list {
      border-top: 1px solid rgb(53, 59, 97);
    }
  
    .card-category {
      background: rgba(239, 242, 246, 0.1);
    }
  
    .pricing-card .list-unstyled li {
      border-bottom: 1px solid rgba(234, 237, 241, 0.1);
    }
  
    .price {
      &.panel-color > .panel-body {
        background-color: rgb(43, 48, 84);
        border: 1px solid rgba(234, 237, 241, 0.1);
        border-bottom: 0;
        border-top: 0;
      }
  
      .panel-footer {
        background-color: rgb(43, 48, 84);
        border: 1px solid rgba(234, 237, 241, 0.1);
      }
    }
  
    .pricing .list-unstyled li {
      border-bottom: 1px solid rgba(234, 237, 241, 0.1);
    }
  
    .card--events .list-group-item h6 {
      color: $white-6;
    }
  
    .rdiobox span:before {
      background-color: #1d2244;
      border: 1px solid #4a5677;
    }
  
    .colorinput-color {
      border: 1px solid rgba(234, 240, 247, 0.2);
    }
  
    .nice-select {
      .list {
        background-color: $dark-theme;
        -webkit-box-shadow: 0px 0px 15px 1px rgb(4, 17, 56);
        box-shadow: 0px 0px 15px 1px rgb(4, 17, 56);
      }
  
      .option {
        &:hover, &.focus, &.selected.focus {
          background-color: rgba(237, 239, 245, 0.1);
        }
      }
    }
  
    .item-card {
      .cardtitle a {
        color: #fefefe;
      }
  
      .cardprice span {
        color: #dfe5ec;
      }
    }
  
    .bd-b {
      border-bottom: 1px solid rgb(53, 59, 97);
    }
  
    .bd-r {
      border-right: 1px solid rgb(53, 59, 97);
    }
  
    .bd-t {
      border-top: 1px solid rgb(53, 59, 97);
    }
  
    .bd-l {
      border-left: 1px solid rgb(53, 59, 97);
    }
  
    .bd-y {
      border-top: 1px solid rgb(53, 59, 97);
      border-bottom: 1px solid rgb(53, 59, 97);
    }
  
    .bd-x {
      border-left: 1px solid rgb(53, 59, 97);
      border-right: 1px solid rgb(53, 59, 97);
    }
  
    .bg-gray-500 {
      background-color: rgba(151, 163, 185, 0.2);
    }
  
    .bg-gray-400 {
      background-color: #5e687d;
    }
  
    .main-card-signin {
      background-color: $dark-theme !important;
      border: 1px solid rgba(227, 227, 227, 0.1);
    }
  
    .main-signin-header h4 {
      color: $white;
    }
  
    .main-signin-footer a {
      color: $white-8;
    }
  
    .main-signup-footer a {
      color: $white;
    }
  
    .main-error-wrapper {
      h1 {
        color: $white-8;
      }
  
      h2 {
        color: $white-5;
      }
  
      h6 {
        color: $white-3;
      }
    }
  
    .construction .btn.btn-icon {
      border: 1px solid rgba(221, 230, 241, 0.1);
    }
  }
  
  .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 2px;
  }
  
  .dark-theme {
    .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      background-color: #3b4563 !important;
    }
  
    .horizontalMenucontainer {
      .desktop-logo, .desktop-logo-dark {
        display: none;
      }
    }
  
    .desktop-dark {
      display: block;
    }
  }
  
  @media (min-width: 768px) {
    .dark-theme.sidebar-mini.sidenav-toggled {
      .main-sidebar-header {
        .icon-light .logo-icon {
          display: none;
          height: 2.5rem;
        }
  
        .icon-dark .logo-icon.dark-theme {
          display: block;
          height: 2.5rem;
		  margin-right: 10px;
        }
      }
  
      &.sidenav-toggled1 .main-sidebar-header .logo-light .main-logo {
        display: none;
      }
    }
  }
  
  @media (max-width: 991px) and (min-width: 568px) {
    .dark-theme .horizontalMenucontainer .desktop-dark {
      margin-left: 2.4rem;
    }
  }
  
  .desktop-dark {
    height: 2rem;
  }
  
  @media (max-width: 567px) {
    .dark-theme {
      .horizontalMenucontainer .desktop-dark, .desktop-logo-1 {
        display: none;
      }
  
      .horizontalMenucontainer .desktop-logo-dark {
        display: block;
        margin-left: 2.5rem;
        height: 2.5rem;
      }
    }
  }
  
  @media (max-width: 991px) {
    .dark-theme {
      .animated-arrow span {
        background: $white;
  
        &:before, &:after {
          background: $white;
        }
      }
  
      &.active .animated-arrow span {
        background-color: transparent;
      }
    }
  }
  
  .dark-theme {
    .sidebar {
      background: $dark-theme;
    }
  
    .main-calendar.fc-list-empty {
      background-color: $dark-theme;
      border: 1px solid #1d2244;
    }
  }
  
  @media (max-width: 575px) {
    .dark-theme .main-calendar .fc-header-toolbar button {
      &.fc-month-button::before, &.fc-agendaWeek-button::before, &.fc-agendaDay-button::before, &.fc-listWeek-button::before, &.fc-listMonth-button::before {
        color: $white;
      }
    }
  }
  
  .dark-them {
    .breadcrumb {
      background-color: rgb(53, 59, 97);
    }
  
    table.dataTable {
      > tbody > tr.child ul.dtr-details > li {
        border-bottom: 1px solid rgba(239, 239, 239, 0.1);
      }
  
      &.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child::before {
        background-color: $primary-dark;
      }
    }
  }
  
  /*----- Horizontal-menu -----*/
  
  .dark-theme {
    .horizontal-main.hor-menu {
      background: #262a4a;
     
    }
  
    .hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active {
      color: $white;
      background: rgba(255, 255, 255, 0.1);
    }
  
    .horizontalMenu > .horizontalMenu-list > li > a {
      color: $white;
    }
  
    .horizontalMenucontainer .main-header {
      box-shadow: none;
      border-bottom: 1px solid rgba(220, 231, 245, 0.1);
    }
  
    .hor-menu .horizontalMenu > .horizontalMenu-list > li > a:hover {
        color: #fff;
		background: rgba(255, 255, 255, 0.1);
    }
  
    .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
      background-color: $dark-theme;
      box-shadow: none;
      border: 1px solid rgb(51, 57, 99);
  
      > li > a {
        color: $white-7;
      }
    }
  
    .sub-menu li a:before {
      border-color: $white-7;
    }
  
    .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
      > li > a:hover {
        color: $primary-dark !important;
      }
  
      .sub-menu-sub:after {
        color: $white-8;
      }
  
      > li > ul.sub-menu {
        background-color: $dark-theme;
        box-shadow: 0 8px 16px 0 rgba(8, 13, 25, 0.4);
        border: 1px solid rgba(231, 234, 243, 0.1);
  
        > li > a {
          color: $white-5;
  
          &:hover {
            color: $primary-dark !important;
          }
        }
      }
    }
  
    .mega-menubg {
      background: $dark-theme;
      box-shadow: 0 8px 16px 0 rgba(9, 17, 33, 0.4);
      border: 1px solid rgba(231, 234, 243, 0.1);
    }
  
    .hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a {
      background: $dark-theme;
      color: $white-7;
    }
  
    .mega-menubg.hor-mega-menu h3 {
      color: $white;
    }
  
    .main-profile-menu .dropdown-item + .dropdown-item {
      border-top: 1px solid rgba(222, 228, 236, 0.1);
    }
  
    .rating-scroll h6 {
      color: $white;
    }
  
    .latest-tasks .nav-link {
      &.active, &:hover, &:focus {
        background: transparent;
        color: $primary-dark;
      }
    }
  
    .main-calendar .fc-list-empty {
      background-color: $dark-theme;
      border: 1px solid #384361;
    }
  
    .card.bg-info-transparent {
      background: rgba(23, 162, 184, 0.2) !important;
    }
  
    .form-control::placeholder {
      color: rgba(212, 218, 236, 0.3) !important;
      opacity: 1;
    }
  
    .side-menu__label {
      color: rgb(255, 255, 255);
    }
  
    .side-menu {
      .slide {
        border-bottom: 1px solid rgba(222, 228, 236, 0.07);
      }
  
      .side-menu__icon {
        color: $white;
      }
    }
  
    .slide.is-expanded a {
      color: $white;
    }
  
    .user-info .text-dark {
      color: #fff !important;
    }
  
    .sidebar-navs a {
      background: none;
      border: 1px solid rgba(222, 228, 236, 0.1) !important;
      color: #fff !important;
    }
  
    .project-card .project-content ul span {
      color: $white;
    }
  
    .card-dashboard-events .list-group-item h6 {
      color: $color;
    }
  
    .list-group-item-action {
      color: $white;
    }
  
    .activity-list:before {
      border: 1px solid rgba(222, 228, 236, 0.1);
    }
  
    .task-stat .tasks {
      color: $white;
      border-bottom: 1px solid rgba(222, 228, 236, 0.1);
    }
  
    .table-bordered {
      th, td {
        border:  1px solid rgba(222, 228, 236, 0.1) !important;
      }
    }
  
    .table {
      th, td {
        border-top: 1px solid rgba(222, 228, 236, 0.1);
      }
    }
  
    .main-header {
      background: linear-gradient(45deg, #f33057, #3858f9);
    }
  
    .review-project .table thead th {
      border-top: 1px solid rgba(227, 231, 237, 0.1) !important;
    }
  
    .sidebar-right .event .Day {
      background: #1a233a;
      color: $white;
    }
  
    .events-title {
      color: $white;
    }
  
    .sidebar-right .latest-tasks .label {
      color: $color;
    }
  
    .datetimepicker table {
      td {
        color: rgb(200, 203, 210);
        background: $dark-theme;
      }
  
      th {
        &.prev, &.next, &.switch {
          background-color: $dark-theme;
          color: rgb(255, 255, 255);
        }
  
        &.prev span::before, &.next span::before {
          background-color: $dark-theme;
          color: rgb(200, 203, 210);
        }
  
        &.dow {
          color: rgb(200, 203, 210);
          background: $dark-theme;
        }
  
        &.today:hover {
          background: #414565;
        }
      }
    }
  
    .datetimepicker-days td:hover {
      background: #414565;
    }
  
    .datetimepicker-hours span {
      &.hour:hover, &.minute:hover, &.month:hover, &.year:hover {
        background: #414565;
      }
    }
  
    .datetimepicker-minutes span {
      &.hour:hover, &.minute:hover, &.month:hover, &.year:hover {
        background: #414565;
      }
    }
  
    .datetimepicker-months span {
      &.hour:hover, &.minute:hover, &.month:hover, &.year:hover {
        background: #414565;
      }
    }
  
    .datetimepicker-years span {
      &.hour:hover, &.minute:hover, &.month:hover, &.year:hover {
        background: #414565;
      }
    }
  
    .main-datetimepicker {
      border-color: rgb(51, 57, 99);
    }
  
    .datepicker > {
      .datepicker_header {
        background-color: #414565;
        color: $white;
      }
  
      .datepicker_inner_container > .datepicker_calendar {
        background-color: $dark-theme;
        border: 1px solid rgb(51, 57, 99);
      }
    }
  
    .main-datetimepicker > .datepicker_inner_container > {
      .datepicker_calendar {
        th {
          color: rgb(255, 255, 255);
        }
  
        td {
          &.hover {
            background-color: #414565;
          }
  
          color: rgb(200, 203, 210);
        }
      }
  
      .datepicker_timelist {
        > div.timelist_item {
          &:hover, &:focus {
            background-color: #414565;
          }
        }
  
        border-left: 1px solid rgb(51, 57, 99);
      }
    }
  
    .datepicker {
      > .datepicker_inner_container > .datepicker_timelist {
        > div.timelist_item.hover {
          color: rgb(200, 203, 210);
        }
  
        background-color: $dark-theme;
      }
  
      background-color: $dark-theme;
    }
  
    .main-modal-calendar-event {
      .modal-body {
        background-color: $dark-theme;
      }
  
      .event-start-date {
        color: $white-7;
      }
    }
  }
  
  @media (max-width: 991px) {
    .dark-theme {
      .horizontalMenu > .horizontalMenu-list {
        background: $dark-theme;
  
        > li > a {
          border-bottom-color: rgba(231, 234, 243, 0.1);
        }
      }
  
      .hor-menu .horizontalMenu > .horizontalMenu-list > li {
        > a {
          border-radius: 0;
        }
  
        &:hover .sub-icon {
          color: $white;
          background: rgba(255, 255, 255, 0.1);
        }
      }
  
      .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
        li:hover > a {
          background-color: rgba(253, 254, 255, 0.1);
          color: #eef0f7 !important;
        }
  
        > li > {
          a:hover:before {
            border-color: #eef0f7;
          }
  
          ul.sub-menu > li > a:hover {
            color: #fff !important;
            background-color: rgba(231, 231, 231, 0.1);
          }
        }
      }
  
      .hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li:hover a {
        &:before {
          border-color: #eef0f7 !important;
        }
  
        color: #eef0f7 !important;
      }
  
      .mega-menubg li a:before {
        border-color: #8594ad;
      }
  
      .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu .sub-menu-sub:after {
        display: none;
      }
  
      .mega-menubg {
        background: $dark-theme  !important;
      }
  
      .hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a {
        background: $dark-theme  !important;
        color: #8291af !important;
      }
  
      .dark-logo-1 {
        display: block;
      }
    }
  }
  
  .main-modal-calendar-event .event-end-date {
    color: $white-7;
  }
  
  .dark-theme {
    .tx-gray-900 {
      color: #a7a9b8;
    }
  
    .main-modal-calendar-schedule select {
      background: #414565;
      color: $white;
      border: 1px solid rgb(51, 57, 99);
    }
  
    .main-sidebar-loggedin .user-info h6 {
      color: $white;
    }
  
    .table-responsive > .table-bordered, .alert-default {
      border: 1px solid rgb(51, 57, 99);
    }
  
    .main-nav .nav-link.active {
      color: $primary-dark;
    }
  
    .toast-header {
      color: $white;
      background-color: rgb(29, 34, 68);
    }
  
    .iti__selected-flag {
      border-right: 1px solid rgb(51, 57, 99);
    }
  
    .page-item.disabled .page-link {
      color: #647390;
      background-color: #2d3463;
      border-color: #333963;
    }
  
    .bd-primary {
      border-color: $primary-dark;
    }
  
    .bd-success {
      border-color: $success;
    }
  
    .bd-warning {
      border-color: #ffc107;
    }
  
    .bd-danger {
      border-color: $danger;
    }
  
    .bd-info {
      border-color: $info;
    }
  
    .bd-gray-500 {
      border-color: $color;
    }
  
    .setting-menu-footer .divider {
      background: rgb(51, 57, 99);
    }
  
    .email-media .media-body {
      .media-title {
        color: rgb(255, 255, 255);
        font-size: 15px;
      }
  
      small {
        color: rgb(255, 255, 255);
      }
    }
  
    .sticky-pin .horizontal-main.hor-menu {
      box-shadow:none;
    }
  
    .horizontalMenu > .horizontalMenu-list > li > {
      ul.sub-menu li a:before, .horizontal-megamenu .link-list li a:before {
        color: $white-7;
      }
    }
  
    .list-group-item-success {
      color: #1f5c01;
      background-color: #c8e9b8;
    }
  
    .list-group-item-info {
      color: #0c5460;
      background-color: #bee5eb;
    }
  
    .list-group-item-warning {
      color: #856404;
      background-color: #ffeeba;
    }
  
    .list-group-item-danger {
      color: #721c24;
      background-color: #f5c6cb;
    }
  
    .wizard > .steps {
      .disabled a {
        color: $white;
  
        &:hover, &:active {
          color: $white;
        }
      }
  
      a {
        .number, &:hover .number, &:active .number {
          background-color: #30355d;
        }
      }
    }
  
    .iti__country-list {
      background: $dark-theme;
      box-shadow: 0 8px 16px 0 rgb(44, 50, 82);
    }
  
    .iti__country.iti__highlight {
      background-color: $dark-theme;
    }
  
    .iti__divider {
      border-bottom: 1px solid #414565;
    }
  
    .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
      background-color: #414565;
    }
  
    .bg-danger {
      background-color: #fa5c7c !important;
    }
  
    .bg-primary {
      background-color: $primary-dark !important;
    }
  
   .bg-secondary {
		background-color: #4b5975 !important;
	}
  
    .bg-gray-800 {
      background-color: $dark !important;
    }
  
    .bg-success {
      background-color: #22c03c !important;
    }
  
    .bg-info {
      background-color: $info !important;
  
      p.card-text {
        color: $black;
      }
    }
  
    .main-content-body.main-content-body-contacts .media-body h5 {
      color: $white;
    }
  
    .table-striped tbody tr:nth-of-type(2n+1) {
      background-color: #30365f !important;
    }
  
    .dataTables_wrapper .dataTables_filter input {
      color: $white;
  
      &::placeholder {
        color: $color;
      }
    }
  
    .dataTables_paginate .page-link {
      background: transparent;
    }
  
    table.dataTable tbody td.sorting_1 {
      background: #313765;
    }
  
    .dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
      background-color: #3f4562;
      color: $white;
  
      &:hover, &:focus {
        background-color: #3f4562;
        color: $white;
      }
    }
  
    .main-nav-line .nav-link.active {
      color: $white;
    }
  }
  
 
  @media (max-width: 991.98px) {
    .dark-theme {
      .horizontalMenucontainer .main-header.hor-header.header-sticky {
        box-shadow: 0 0 10px rgba(20, 28, 43, 0.8);
      }
  
      .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
        box-shadow: none;
        border: 0;
  
        > li > ul.sub-menu {
          box-shadow: none;
          border: 0;
        }
      }
    }
  }
  
  @media (min-width: 768px) {
    .dark-theme .main-content-left-contacts {
      border-right: 1px solid rgb(53, 59, 97);
    }
  }
  
  @media (max-width: 578px) {
    .dark-theme {
      .logo-icon {
        display: block;
        height: 2.2rem;
  
        &.mobile-logo.icon-light {
          display: none;
        }
      }
  
      .main-logo.dark-theme {
        display: none;
      }
    }
  }
  
  @media (min-width: 576px) {
    .dark-theme .main-calendar .fc-header-toolbar button {
      color: $white;
    }
  }
  
  @media (max-width: 575px) {
    .main-calendar .fc-header-toolbar button {
      color: $white;
    }
  }
  
  @media (max-width: 605px) {
    .dark-theme .fc-view, .fc-view > table {
      border: 1px solid rgba(226, 232, 245, 0.2);
    }
  }
  .dark-theme .gray-set {
   
     .bg-gray-100 {
   	background-color:$gray-100;
     }
     
     .bg-gray-200 {
   	background-color: $gray-200;
     }
     
     .bg-gray-300 {
   	background-color: $gray-300;
     }
     
     .bg-gray-400 {
   	background-color: $gray-400;
     }
     
     .bg-gray-500 {
   	background-color: $gray-500;
     }
     
     .bg-gray-600 {
   	background-color: $gray-600;
     }
     
     .bg-gray-700 {
   	background-color: $gray-700;
     }
     
     .bg-gray-800 {
   	background-color: $gray-800 !important;
     }
     
     .bg-gray-900 {
   	background-color: $gray-900;
     }
  
}
.dark-theme .list-group.projects-list .list-group-item-action:hover,  .dark-theme .list-group.projects-list .list-group-item-action:focus {
    color: $white;
    background-color: rgba(246, 245, 255, 0.1);
}
.dark-theme .navbar-form.active .input-group-btn .btn i {
    color: $white !important;
}
.dark-theme .slide-item.active:before, .dark-theme .slide-menu li a:hover:before { 
    color: $primary-dark !important; 
}
.dark-theme {
  .main-sidebar {
    border-right: 0 solid #2b3054;
  }

  .ps > .ps__rail-y {
    width: 2px;
    background-color: #434869;
  }

  .slide.is-expanded .sub-side-menu__item:before {
    color: #fff;
  }

  .sub-slide.is-expanded .sub-side-menu__item {
    color: $primary-dark;
  }

  .slide.is-expanded .sub-slide-item {
    &.active, &:hover, &:focus {
      color: $primary-dark;
    }
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a:hover {
    color: $primary-dark !important;
  }

  .hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li:hover a:before {
    color: $primary-dark !important;
  }
}
@media (min-width: 768px) {
  .dark-theme {
    &.sidebar-mini.sidenav-toggled.sidenav-toggled1 {
      .side-menu__label, .slide-menu .slide-item:before {
        color: #fff;
      }

      .main-sidebar-header .icon-dark .logo-icon.dark-theme {
        display: none;
        height: 2.5rem;
      }

      .desktop-logo.logo-light {
        display: none;
        margin: 0 auto;
      }
    }

    &.leftmenu-light.sidebar-mini.sidenav-toggled.sidenav-toggled1 {
      .side-menu__label, .slide-menu .slide-item:before, .sub-slide-menu .sub-slide-item:before {
        color: #2d3144;
      }

      .main-sidebar-header .logo-light .main-logo {
        display: block;
      }
    }
  }

  .app.sidebar-mini.dark-theme.leftmenu-light.sidenav-toggled.sidenav-toggled1 .main-sidebar-header .desktop-logo.icon-logo {
    display: block;
    height: 2.5rem;
  }

  .leftmenu-light .side-menu .slide {
    border-bottom: 1px dotted #ecedf2;
  }
}

/* ############ general ############ */

.dark-theme .btn-primary {
	color: $white;
	background-color: $primary-dark !important;
	border-color: $primary !important;
  
	&:hover {
	  color: $white;
	  background-color: #4e5af1;
	  border-color: #4e5af1;
	}
  
	&:focus, &.focus {
	  box-shadow: 0 0 0 0.2rem rgba(82, 125, 255, 0.5);
	}
  
	&.disabled, &:disabled {
	  color: $white;
	  background-color: $primary;
	  border-color: $primary;
	}
  
	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: #4e5af1;
		border-color: #4e5af1;
	  }
	}
  }
  .dark-theme {
  .hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a.active {
    color: $primary-dark !important;
  }

  a {
    color: #bcc0c7 !important;

    &:hover {
      color: #387cf9;
    }
  }

  .text-primary {
    color: #387cf9 !important;
  }

  .text-purple {
    color: #8755e0 !important;
  }

  .activity .added-project {
    color:  $primary-dark;
  }

  .card-dashboard-events .list-group-item label {
    color: #fff;
  }

  .projects-list .list-group-item {
    background-color: #2b3054;
    border-bottom-color: rgba(231, 235, 243, 0.1) !important;
    border-left: 0;
    border-right: 0;
  }

  .card-dashboard-events .list-group-item {
    border-bottom-color: rgba(231, 235, 243, 0.1) !important;
    border-left: 0;
    border-right: 0;
  }

  .breadcrumb .breadcrumb-item a {
    color: #edf0f5;
  }

  .card-header .tx-gray-500, p.tx-gray-500 {
    color: #97a3b9 !important;
  }

  .main-nav-column .nav-link.active {
    &:hover, &:focus, i, &:hover i, &:focus i {
      color:  $primary-dark;
    }
  }

  .btn-main-primary, .btn-primary {
    color: #fff !important;
  }

  .main-chat-body .media.flex-row-reverse .main-msg-wrapper {
    background-color: #3b7df7;
  }

  .slide-item {
    &.active, &:hover, &:focus {
      color: $primary-dark !important;
    }
  }

  .main-chat-list {
    .media.selected {
      border-left: 1px solid  $primary-dark;
    }

    .media-contact-name span:last-child {
      color: rgb(183, 190, 206);
    }
  }

  .main-chat-footer .nav-link {
    color: rgb(183, 190, 206);
  }

  .card-link {
    color: #fefeff;
  }

  .tree li i {
    color:  $primary-dark;
  }

  .nav-tabs .nav-link {
    background-color: rgb(24, 29, 70);
  }

  .btn-secondary, .btn-success, .btn-danger {
    color: #fff !important;
  }

  .btn-warning {
    color: #2a283c !important;
  }

  .btn-info, .btn-pink {
    color: #fff !important;
  }

  .btn-outline-primary {
    color:  $primary-dark;
    border-color:  $primary-dark;

    &:hover {
      color: #fff;
      border-color:  $primary-dark;
      background: $primary-dark !important;
    }
  }

  .btn-primary {
    color: #fff;
    background-color:  $primary-dark;
    border-color:  $primary-dark;
  }

  .br-theme-bars-1to10 .br-widget .br-current-rating, .br-theme-bars-movie .br-widget .br-current-rating, .br-theme-bars-pill .br-widget a {
    color:  $primary-dark;
  }

  .br-theme-bars-horizontal .br-widget .br-current-rating {
    color:  $primary-dark;
    color:  $primary-dark;
  }

  .pagination .page-link {
    color: #fff;
    background-color: #1d2244;
  }

  .page-item.active .page-link {
    background-color: #3858f9;
  }

  .btn-primary:hover {
    color: #fff;
    background-color:  $primary-dark;
    border-color:  $primary-dark;
  }

  .select2-container--default .select2-selection--multiple .select2-selection__choice, .ckbox span:after {
    background-color:  $primary-dark;
  }

  .main-invoice-list .media-body h6 span:last-child {
    color:  $primary-dark;
  }

  .rdiobox input[type='radio']:checked + span:before, .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color:  $primary-dark;
  }

  .main-signin-header h2 {
    color:  $primary-dark;
  }
}
.dark-theme {
  .main-content-left-contacts {
    border-left: 1px solid #353b61;
  }

  .btn-outline-success {
    color: #0ba360 !important;

    &:hover {
      background: #17a2b8 !important;
      color: #fff !important;
    }
  }

  .btn-outline-info {
    color: #17a2b8 !important;

    &:hover {
      background: #0ba360 !important;
      color: #fff !important;
    }
  }

  .btn-outline-danger {
    color: #f53c5b !important;

    &:hover {
      background: #f53c5b !important;
      color: #fff !important;
    }
  }

  .main-mail-compose-body {
    background-color: #1b223a;

    .form-group + .form-group {
      border-top: 1px dotted #474e82;
    }
  }
}

.main-mail-compose-body .form-group .form-control {
  padding: 12px;
}

.dark-theme {
  .main-mail-compose-body .form-group .form-label {
    color: #eae9f1;
  }

  .main-mail-compose-header {
    background-color: #1b223a;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    .nav-link {
      color: rgba(255, 255, 255, 0.3);
    }
  }
}
.dark-theme  .main-mail-compose-body .form-group .nav-link {
    color: #dadee6;
}
.dark-theme  .form-control:disabled, .dark-theme  .form-control[readonly] {
    background-color: #272b46 !important;
}
/* ############ general ############ */


.leftmenu-light.dark-theme.sidebar-mini.sidenav-toggled .slide .side-menu__label {
  color:#383535;
}
.leftmenu-light.dark-theme.sidebar-mini.sidenav-toggled .slide:hover .slide-menu{
  background-color:#ffffff;
}
   @media(min-width: 768px){
 .leftmenu-light.dark-theme .slide a{
  color:#181819 !important;
 }
 }
 
 

 .dark-theme .text-dark {
   color:#fff !important;
 }
 
 .dark-theme .card-footer.bg-white{
 background-color:#2b3054 !important;
 }